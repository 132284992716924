import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import moment from 'moment';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {
  H1White, H2White, H4White, PWhite,
} from '../components/typography';

const StyledH1White = styled(H1White)`
  margin-top: 25px;
  margin-bottom: 35px;

  @media (min-width: 64em) {
    margin-top: 49px;
    margin-bottom: 67px;
  }
`;

const StyledH2White = styled(H2White)`
  margin-bottom: 40px;

  @media (max-width: 64em) {
    margin-top: 20px;
  }
`;

const StyledH4White = styled(H4White)`
  margin-bottom: 40px;
`;

const StyledGrid = styled(Grid)`
  max-width: 89rem;
  margin-bottom: 50px;
  width: 100%;

  @media (min-width: 64em) {
    margin-bottom: 106px;
  }
`;

const Container = styled.div`
  &:hover .title {
    color: var(--blue);
    transition: color .5s ease;
  }
`;

const News = ({ data }) => (
  <Layout>
    <SEO title={data.datoCmsNews.title} />
    <StyledGrid fluid>
      <Row>
        <Col lg={12} md={12} xs={12}>
          <Img
            fluid={data.datoCmsNews.cover.fluid}
            alt={data.datoCmsNews.title}
            style={{ height: 530 }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={7} lgOffset={1} md={12} sm={12} xs={12}>
          <StyledH1White>{data.datoCmsNews.title}</StyledH1White>
        </Col>
      </Row>
      <Row>
        <Col lg={7} xs={12} md={7} lgOffset={1}>
          <StyledH2White>{moment(data.datoCmsNews.meta.publishedAt).format('MMM YYYY')}</StyledH2White>
          <PWhite>{data.datoCmsNews.description}</PWhite>
        </Col>
        <Col lg={4} md={5} xs={12}>
          <StyledH2White>recent news</StyledH2White>
          {data.allDatoCmsNews.edges.map(({ node: news }) => (
            <Container>
              <Link to={`/news/${news.slug}`} key={news.id}>
                <Img fluid={{ ...news.cover.fluid }} />
                <StyledH4White className="title">{news.title}</StyledH4White>
              </Link>
            </Container>
          ))}
        </Col>
      </Row>
    </StyledGrid>
  </Layout>
);

News.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default News;

export const query = graphql`
  query NewsQuery($slug: String!) {
    datoCmsNews(slug: { eq: $slug }) {
      id
      title
      description
      slug
      meta {
        publishedAt
      }
      cover {
        fluid(maxHeight: 530) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
    allDatoCmsNews(
      sort: { 
        fields: [meta___publishedAt],
        order: DESC },
        limit: 4,
        filter: {slug: {ne: $slug}}
    ) {
      edges {
        node {
          id
          title
          slug
          meta {
            publishedAt
            isValid
            status
          }
          cover {
            fluid(maxHeight: 375) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;
